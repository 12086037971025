import React from 'react';
import { Link } from 'react-router-dom';
import { Router } from 'react-router';
import { connect } from 'dva';
import { Empty, Card, Typography, Alert, Input, Button, Table, Radio, Divider, Switch, Row, Col, Avatar, Pagination, Tabs, Modal, Popconfirm } from 'antd';
import { LeftOutlined, UserOutlined, LockOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import jwt_decode from "jwt-decode";
import { Form, Checkbox } from 'antd';
const { Search } = Input;
const { Text } = Typography;


class SweetTipsAddEdit extends React.Component {

    isLoadedDetils = false;
    state = {
        availability: true,
        sweetedit:[],
    };
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            let data = JSON.parse(localStorage.getItem('sweetedit'))
            console.log("componentDidMount", data);
            this.formRef.resetFields();
            this.formRef.setFieldsValue({
                ['questions']: data.questions, 
                ['answers']: data.answers, 
            })    
        }
    }


    onFinish = (data) => {
        console.log("onfinishresult", data);
        if (!this.props.match.params.id) {
            data.userId = localStorage.getItem('userId');
            this.props.dispatch({ type: 'Sweet/createSweet', payload: { ...data } });
        } else {            
            data.userId = localStorage.getItem('userId');
            data.faq_id = this.props.match.params.id;
            this.props.dispatch({ type: 'Sweet/updateSweet', ...data  });
        }
        this.formRef.resetFields();
        setTimeout(() => {
            this.props.history.push('/sweet-tips');
        }, 500);
    }

    onChangeAvailability = (e) => {
        this.setState({ availability: e.target.checked });
    }

    render() {
        let roleType = localStorage.getItem('role');
        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        };
        const tailLayout = {
            wrapperCol: { offset: 7, span: 40 },
        };
        
        return (
            <div>
                <div className="innerContainer">
                    <Card title={<div><span><LeftOutlined onClick={() => this.props.history.push('/sweet-tips')} /></span>&nbsp;{this.props.match.params.id ? "Edit Sweet" : "Add Sweet"}</div>} bodyStyle={{ padding: '0 15px 15px' }}>
                        <div style={{ padding: "1rem" }} >
                            <Form
                                {...layout}
                                name="basic"
                                initialValues={{ remember: true }}
                                onFinish={this.onFinish}
                                ref={(ref) => this.formRef = ref} >

                                <Form.Item
                                    label="Question"
                                    name="questions"
                                    style={{width:"500px"}}
                                    rules={[{ required: true, message: 'Please input questions!' } ,{ max: 100, message: 'Question must not be greater than 100 characters.' },]} >
                                    <Input />
                                </Form.Item>
                            
                                <Form.Item
                                    label="Answer"
                                    name="answers"
                                    style={{width:"500px"}}
                                    rules={[{ required: true, message: 'Please input answer!' },{ max: 400, message: 'Answer must not be greater than 400 characters.' },]} >
                                    <Input.TextArea  rows={4} />
                                </Form.Item> 

                                <Form.Item {...tailLayout}>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
};
export default connect(({ FAQ }) => FAQ)(SweetTipsAddEdit);















// import React from 'react';
// import { Form, Input, Button, Row, Col } from 'antd';
// import { postSweetTips } from '../../../services/api';
// class SweetTips extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             questions: '',
//             answers: '',
//             userId:'63d8f5771a02db7966635531'
//         };
//     }

//     handleInputChange = (key, value) => {
//         this.setState({ [key]: value });
//     };

//     handleSubmit = () => {
//         postSweetTips(this.state)
//         console.log('Submitted:', this.state);
//     };

//     render() {
//         const { questions, answers } = this.state;

//         return (
//             <div>
//                 <h1>Sweet Tips</h1>
//                 <Form layout="vertical" onFinish={this.handleSubmit}>
//                     <Row gutter={[16, 16]}>
//                         <Col span={24}>
//                             <Form.Item label="Question" name="questions" initialValue={questions}>
//                                 <Input
//                                     placeholder="Enter your question"
//                                     onChange={e => this.handleInputChange('questions', e.target.value)}
//                                 />
//                             </Form.Item>
//                         </Col>
//                         <Col span={24}>
//                             <Form.Item label="Answer" name="answers" initialValue={answers}>
//                                 <Input.TextArea
//                                     rows={4}
//                                     placeholder="Enter your answer"
//                                     onChange={e => this.handleInputChange('answers', e.target.value)}
//                                 />
//                             </Form.Item>
//                         </Col>
//                         <Col span={24}>
//                             <Form.Item>
//                                 <Button type="primary" htmlType="submit">Save</Button>
//                             </Form.Item>
//                         </Col>
//                     </Row>
//                 </Form>
//             </div>
//         );
//     }
// }

// export default SweetTips;
