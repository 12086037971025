import { getFrequentlyAskedQuestions, getFrequentlyAskedQuestionsByUserId, deleteFrequentlyAskedQuestion, createFrequentlyAskedQuestion, updateFrequentlyAskedQuestion }
  from '../services/api'
import { getSweetTips, createSweetTips, deleteSweetTips, updateSweet } from '../services/api'
import { message } from 'antd';

export default {
  namespace: 'Sweet',

  state: {
    list: [],
    create: null,
    delete: null
  },

  subscriptions: {
    setup({ dispatch, history }) {
    },
  },

  effects: {
    *listSweet({ payload }, { call, put }) {
      let response = {};
      response = yield call(getSweetTips);
      console.log("responseresponseresponseresponseresponseresponse", response)
      if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
      yield put({ type: 'list', data: [...response.faq_list] });
      // console.log("datadatadatadatadata",data);
    },
    *listSweetBySeller({ payload }, { call, put }) {
      let response = {};
      response = yield call(getFrequentlyAskedQuestionsByUserId, payload);
      if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
      yield put({ type: 'list', data: [...response.result] });
    },
    *createSweet({ payload }, { call, put }) {
      let response = {};
      response = yield call(createSweetTips, payload);
      if (response.status) { message.success(response.message, 5); }

      if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
      yield put({ type: 'create', data: response.status });
    },
    *updateSweet( payload , { call, put }) {
      console.log('payload',payload);
      let response = {};
      response = yield call(updateSweet, payload);
      if (response.status) { message.success(response.message, 5); }

      if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
      yield put({ type: 'create', data: response.status });
    },

    *deleteSweet({ payload }, { call, put }) {
      let response = {};
      response = yield call(deleteSweetTips, payload);
      console.log('response', response);
      if (response.status) { message.success(response.message, 5); }

      if (!response.status) { message.error(response.msg || response.message || response.err, 5); }
      yield put({ type: 'delete', data: response.status });
    }
  },

  reducers: {
    list(state, action) {
      return { ...state, list: [...action.data] };
    },
    create(state, action) {
      return { ...state, create: action.data, delete: null };
    },
    delete(state, action) {
      return { ...state, delete: action.data, create: null };
    },
    clear(state, action) {
      return { ...state, delete: null, create: null };
    }
  },
};